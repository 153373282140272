/** @format */

import './App.css';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import foto1 from './img/foto1.jpg';
import foto2 from './img/foto2.jpg';
import foto3 from './img/foto3.jpg';
import foto4 from './img/foto4.jpg';
import foto5 from './img/foto5.jpg';
import { useEffect, useRef, useState } from 'react';
import { Fancybox } from '@fancyapps/ui';
import '@fancyapps/ui/dist/fancybox/fancybox.css';

gsap.registerPlugin(ScrollTrigger);
function Photography() {
  Fancybox.bind('[data-fancybox]', {
    // Your options go here
  });

  const [catalogues, setCatalogues] = useState([]);

  // const gsapAnimation = () => {
  //   gsap.to('.nav', {
  //     scrollTrigger: {
  //       trigger: '.nav',
  //       start: 'top top',
  //       end: () => `+=500%`,
  //       scrub: 1,
  //       pin: true,
  //       toggleClass: {
  //         targets: '.nav a',
  //         className: 'active',
  //       },
  //       onLeave: () => {
  //         // This callback is triggered when scrolling back up
  //         const navLinks = document.querySelectorAll('.nav a');
  //         navLinks.forEach((link) => {
  //           link.classList.add('active');
  //         });
  //       },
  //       markers: true,
  //     },
  //     color: 'rgba(4, 4, 4, 0.2)',
  //     background: 'rgba(4, 4, 4, 0.2)',
  //     borderRadius: '16px',
  //     boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
  //     backdropFilter: 'blur(5px)',
  //     webkitbackdropFilter: 'blur(5px)',
  //     border: '1px solid rgba(4, 4, 4, 0.3)',
  //     duration: 0.2,
  //     zIndex: 100,
  //   });
  // };

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await fetch('https://admin.rikinovalsuherman.com/api/photos', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        const data = await response.json();
        setCatalogues(data);

        return data;
      } catch (error) {
        console.log('Error: ', error);
      }
    };

    fetchImages();
    // gsapAnimation();
  }, []);

  return (
    <div className='App'>
      <div className='nav'>
        <div className='nav-title'>
          <a href='/'>
            <p>{'<'}</p>
          </a>
          <a href='/'>
            <h4>Photography</h4>
          </a>
        </div>
      </div>
      &nbsp;
      {catalogues &&
        catalogues.map((catalogue, index) => (
          <div key={catalogue.catalogue.name} className={`gallery-section-${catalogue.catalogue.name} gallery-section-${index}`}>
            <div className='gallery-section'>
              <div className='gallery-title'>
                <p>{catalogue.catalogue.name}</p>
                <div className='arrow-text'>
                  {/* <a href='/photography'>
                    <p>{'>'}</p>
                  </a> */}
                </div>
                <p></p>
              </div>
              <div className='gallery'>
                {catalogue.catalogue.photos.map((photo) => (
                  <div key={photo.id} className='gallery-item'>
                    <img src={photo.photo_url} alt='' data-fancybox='gallery' data-caption={photo.photo_caption} />
                  </div>
                ))}
              </div>
            </div>
            &nbsp;
          </div>
        ))}
      {/* <div className='gallery-section'>
        <div className='gallery-title'>
          <p>
            <a href='/photography'>Beauty / Fashion Gallery</a>
          </p>
          <div className='arrow-text'>
            <a href='/photography'>
              <p>{'>'}</p>
            </a>
          </div>
          <p></p>
        </div>
        <div className='gallery'>
          <div className='gallery-item'>
            <img
              src='https://drive.google.com/uc?id=146R7yWXqy9DGFhRrCzmhkyy0roDAO4DB'
              alt=''
              data-type='iframe'
              data-fancybox='gallery'
              data-caption='Caption #1'
            />
          </div>
          <div className='gallery-item'>
            <img src={foto2} alt='' data-fancybox='gallery' data-caption='Caption #2' />
          </div>
          <div className='gallery-item'>
            <img src={foto3} alt='' data-fancybox='gallery' data-caption='Caption #3' />
          </div>
          <div className='gallery-item'>
            <img src={foto4} alt='' data-fancybox='gallery' data-caption='Caption #4' />
          </div>
          <div className='gallery-item'>
            <img src={foto5} alt='' data-fancybox='gallery' data-caption='Caption #5' />
          </div>
          <div className='gallery-item'>
            <img src={foto5} alt='' data-fancybox='gallery' data-caption='Caption #6' />
          </div>
          <div className='gallery-item'>
            <img src={foto1} alt='' data-fancybox='gallery' data-caption='Caption #1' />
          </div>
          <div className='gallery-item'>
            <img src={foto2} alt='' data-fancybox='gallery' data-caption='Caption #2' />
          </div>
          <div className='gallery-item'>
            <img src={foto3} alt='' data-fancybox='gallery' data-caption='Caption #3' />
          </div>
          <div className='gallery-item'>
            <img src={foto4} alt='' data-fancybox='gallery' data-caption='Caption #4' />
          </div>
          <div className='gallery-item'>
            <img src={foto5} alt='' data-fancybox='gallery' data-caption='Caption #5' />
          </div>
          <div className='gallery-item'>
            <img src={foto5} alt='' data-fancybox='gallery' data-caption='Caption #6' />
          </div>
        </div>
      </div>
      &nbsp; */}
      {/* <div className='gallery-section'>
        <div className='gallery-title'>
          <p>
            <a href='/photography'>Beauty / Fashion Gallery</a>
          </p>
          <div className='arrow-text'>
            <a href='/photography'>
              <p>{'>'}</p>
            </a>
          </div>
          <p></p>
        </div>
        <div className='gallery'>
          <div className='gallery-item'>
            <img src={foto1} alt='' data-fancybox='gallery' data-caption='Caption #1' />
          </div>
          <div className='gallery-item'>
            <img src={foto2} alt='' data-fancybox='gallery' data-caption='Caption #2' />
          </div>
          <div className='gallery-item'>
            <img src={foto3} alt='' data-fancybox='gallery' data-caption='Caption #3' />
          </div>
          <div className='gallery-item'>
            <img src={foto4} alt='' data-fancybox='gallery' data-caption='Caption #4' />
          </div>
          <div className='gallery-item'>
            <img src={foto5} alt='' data-fancybox='gallery' data-caption='Caption #5' />
          </div>
          <div className='gallery-item'>
            <img src={foto5} alt='' data-fancybox='gallery' data-caption='Caption #6' />
          </div>
        </div>
      </div>
      &nbsp; */}
    </div>
  );
}

export default Photography;
