/** @format */

import './App.css';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import foto1 from './img/foto1.jpg';
import foto2 from './img/foto2.jpg';
import foto3 from './img/foto3.jpg';
import foto4 from './img/foto4.jpg';
import foto5 from './img/foto5.jpg';
import { useEffect, useState } from 'react';
import { Fancybox } from '@fancyapps/ui';
import '@fancyapps/ui/dist/fancybox/fancybox.css';

gsap.registerPlugin(ScrollTrigger);
function Videography() {
  Fancybox.bind('[data-fancybox]', {
    // Your options go here
  });

  const [catalogues, setCatalogues] = useState([]);

  const gsapAnimation = () => {
    gsap.to('.nav', {
      scrollTrigger: {
        trigger: '.nav',
        start: 'top top',
        end: () => document.body.scrollHeight - window.innerHeight,
        scrub: 1,
        pin: true,
        toggleClass: {
          targets: '.nav a',
          className: 'active',
        },
        onLeave: () => {
          // This callback is triggered when scrolling back up
          const navLinks = document.querySelectorAll('.nav a');
          navLinks.forEach((link) => {
            link.classList.add('active');
          });
        },
        // markers: true,
      },
      background: 'rgba(4, 4, 4, 0.2)',
      borderRadius: '16px',
      boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
      backdropFilter: 'blur(5px)',
      webkitbackdropFilter: 'blur(5px)',
      border: '1px solid rgba(4, 4, 4, 0.3)',
      duration: 0.5,
      zIndex: 100,
    });
  };
  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const response = await fetch('https://admin.rikinovalsuherman.com/api/videos', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        const data = await response.json();
        setCatalogues(data);

        return data;
      } catch (error) {
        console.log('Error: ', error);
      }
    };

    fetchVideos();
  }, []);

  return (
    <div className='App'>
      <div className='nav'>
        <div className='nav-title'>
          <a href='/'>
            <p>{'<'}</p>
          </a>
          <a href='/'>
            <h4>Videography</h4>
          </a>
        </div>
      </div>
      &nbsp;
      {catalogues &&
        catalogues.map((catalogue) => (
          <div key={catalogue.catalogue.name}>
            <div className='video-section'>
              <div className='video-title'>
                <p>
                  {/* <a href='/videography'>
                    </a> */}
                  {catalogue.catalogue.name}
                </p>
                <div className='arrow-text'>
                  {/* <a href='/videography'>
                    <p>{'>'}</p>
                  </a> */}
                </div>
                <p></p>
              </div>
              <div className='video'>
                {catalogue.catalogue.videos.map((video) => (
                  <div className='video-item' key={video.id}>
                    <a href={video.video_url} data-fancybox='video' data-type='html5video' data-caption='Caption #1'>
                      <img src={video.video_thumbnail_url} alt='' />
                    </a>
                  </div>
                ))}
              </div>
            </div>
            &nbsp;
          </div>
        ))}
      {/* <div className='video-section'>
        <div className='video-title'>
          <p>
            <a href='/videography'>Beauty / Fashion Video</a>
          </p>
          <div className='arrow-text'>
            <a href='/videography'>
              <p>{'>'}</p>
            </a>
          </div>
          <p></p>
        </div>
        <div className='video'>
          <div className='video-item'>
            <a
              href='https://drive.google.com/file/d/1AUgao0oI8PXcAm2XRll9ytrmAyYuhtnY/preview'
              data-fancybox='video'
              data-type='iframe'
              data-caption='Caption #1'
            >
              <img src='https://img.youtube.com/vi/tY3ShI1K7ac/maxresdefault.jpg' alt='' />
            </a>
          </div>
          <div className='video-item'>
            <a href='https://www.youtube.com/watch?v=zsxiE8tGvMs' data-fancybox='video' data-caption='Caption #2'>
              <img src='https://img.youtube.com/vi/zsxiE8tGvMs/maxresdefault.jpg' alt='' />
            </a>
          </div>
          <div className='video-item'>
            <a href='https://www.youtube.com/watch?v=h5mZYrUMz34' data-fancybox='video' data-caption='Caption #3'>
              <img src='https://img.youtube.com/vi/h5mZYrUMz34/maxresdefault.jpg' alt='' />
            </a>
          </div>
          <div className='video-item'>
            <a href='https://www.youtube.com/watch?v=BlDYh-k-OU0' data-fancybox='video' data-caption='Caption #4'>
              <img src='https://img.youtube.com/vi/BlDYh-k-OU0/maxresdefault.jpg' alt='' />
            </a>
          </div>
          <div className='video-item'>
            <a href='https://www.youtube.com/watch?v=ESAQfRPdfa0' data-fancybox='video' data-caption='Caption #5'>
              <img src='https://img.youtube.com/vi/ESAQfRPdfa0/maxresdefault.jpg' alt='' />
            </a>
          </div>
          <div className='video-item'>
            <a href='https://www.youtube.com/watch?v=Ba_fbDRTtDI' data-fancybox='video' data-caption='Caption #6'>
              <img src='https://img.youtube.com/vi/Ba_fbDRTtDI/maxresdefault.jpg' alt='' />
            </a>
          </div>
          <div className='video-item'>
            <a href='https://www.youtube.com/watch?v=Ba_fbDRTtDI' data-fancybox='video' data-caption='Caption #6'>
              <img src='https://img.youtube.com/vi/Ba_fbDRTtDI/maxresdefault.jpg' alt='' />
            </a>
          </div>
          <div className='video-item'>
            <a href='https://www.youtube.com/watch?v=Ba_fbDRTtDI' data-fancybox='video' data-caption='Caption #6'>
              <img src='https://img.youtube.com/vi/Ba_fbDRTtDI/maxresdefault.jpg' alt='' />
            </a>
          </div>
          <div className='video-item'>
            <a href='https://www.youtube.com/watch?v=Ba_fbDRTtDI' data-fancybox='video' data-caption='Caption #6'>
              <img src='https://img.youtube.com/vi/Ba_fbDRTtDI/maxresdefault.jpg' alt='' />
            </a>
          </div>
          <div className='video-item'>
            <a href='https://www.youtube.com/watch?v=Ba_fbDRTtDI' data-fancybox='video' data-caption='Caption #6'>
              <img src='https://img.youtube.com/vi/Ba_fbDRTtDI/maxresdefault.jpg' alt='' />
            </a>
          </div>
          <div className='video-item'>
            <a href='https://www.youtube.com/watch?v=Ba_fbDRTtDI' data-fancybox='video' data-caption='Caption #6'>
              <img src='https://img.youtube.com/vi/Ba_fbDRTtDI/maxresdefault.jpg' alt='' />
            </a>
          </div>
          <div className='video-item'>
            <a href='https://www.youtube.com/watch?v=Ba_fbDRTtDI' data-fancybox='video' data-caption='Caption #6'>
              <img src='https://img.youtube.com/vi/Ba_fbDRTtDI/maxresdefault.jpg' alt='' />
            </a>
          </div>
          <div className='video-item'>
            <a href='https://www.youtube.com/watch?v=Ba_fbDRTtDI' data-fancybox='video' data-caption='Caption #6'>
              <img src='https://img.youtube.com/vi/Ba_fbDRTtDI/maxresdefault.jpg' alt='' />
            </a>
          </div>
        </div>
      </div>
      &nbsp; */}
    </div>
  );
}

export default Videography;
