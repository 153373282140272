/** @format */

import { useEffect, useState } from 'react';
import './App.css';
import gsap from 'gsap';
import foto1 from './img/foto1.jpg';
import foto2 from './img/foto2.jpg';
import foto3 from './img/foto3.jpg';
import foto4 from './img/foto4.jpg';
import foto5 from './img/foto5.jpg';
import profile from './img/profile.jpeg';
import ft1 from './img/ft1.webp';
import ft2 from './img/ft2.webp';
import ft3 from './img/ft3.webp';
import ft4 from './img/ft4.webp';
import ft5 from './img/ft5.webp';
import ft6 from './img/ft6.webp';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { Fancybox } from '@fancyapps/ui';
import '@fancyapps/ui/dist/fancybox/fancybox.css';

gsap.registerPlugin(ScrollTrigger);

function App() {
  Fancybox.bind('[data-fancybox]', {
    // Your options go here
  });

  const [images, setImages] = useState([]);
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await fetch('https://admin.rikinovalsuherman.com/api/photos-main', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        const data = await response.json();
        setImages(data);

        return data;
      } catch (error) {
        console.log('Error: ', error);
      }
    };

    const fetchVideos = async () => {
      try {
        const response = await fetch('https://admin.rikinovalsuherman.com/api/videos-main', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        const data = await response.json();
        setVideos(data);

        return data;
      } catch (error) {
        console.log('Error: ', error);
      }
    };

    fetchImages();
    fetchVideos();
    // gsapAnimation();
  }, []);

  return (
    <div className='App'>
      <div className='header'>
        <div className='profile'>
          <img src={profile} alt='Riki Noval Suherman' />
          <h4>Riki Noval Suherman</h4>
          <p className='jobdesc'>Freelance Photography & Videography</p>
          <p className='location'>Jakarta, Indonesia</p>
        </div>
        <div className='contact'>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span>
            <a href='https://www.instagram.com/rikiiiiins/' target='_blank'>
              <i class='fa-brands fa-instagram fa-2xl'></i>
            </a>
          </span>
          <span>
            <a href='https://www.tiktok.com/@rikinovalsuherman/' target='_blank'>
              <i class='fa-brands fa-tiktok fa-2xl'></i>
            </a>
          </span>
          <span>
            <a href='https://wa.me/6285951438257' target='_blank'>
              <i class='fa-brands fa-whatsapp fa-2xl'></i>
            </a>
          </span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <div className='gallery-section'>
        <div className='gallery-title'>
          <p>
            <a href='/photography'>Beauty / Fashion Gallery</a>
          </p>
          <div className='arrow-text'>
            <a href='/photography'>
              <p>{'>'}</p>
            </a>
          </div>
          <p></p>
        </div>
        {/* <hr /> */}
        {/* <div className='gallery'>
          <div className='gallery-item'>
            <img src={foto1} alt='' data-fancybox='gallery' data-caption='Caption #1' />
          </div>
          <div className='gallery-item'>
            <img src={foto2} alt='' data-fancybox='gallery' data-caption='Caption #2' />
          </div>
          <div className='gallery-item'>
            <img src={foto3} alt='' data-fancybox='gallery' data-caption='Caption #3' />
          </div>
          <div className='gallery-item'>
            <img src={foto4} alt='' data-fancybox='gallery' data-caption='Caption #4' />
          </div>
          <div className='gallery-item'>
            <img src={foto5} alt='' data-fancybox='gallery' data-caption='Caption #5' />
          </div>
          <div className='gallery-item'>
            <img src={foto5} alt='' data-fancybox='gallery' data-caption='Caption #6' />
          </div>
        </div> */}
        <div className='gallery'>
          {images.map((image) => (
            <div className='gallery-item'>
              <img src={image.photo_url} alt='' data-fancybox='gallery' data-caption={image.photo_caption} />
            </div>
          ))}
        </div>
      </div>
      &nbsp;
      <div className='video-section'>
        <div className='video-title'>
          <p>
            <a href='/videography'>Beauty / Fashion Video</a>
          </p>
          <div className='arrow-text'>
            <a href='/videography'>
              <p>{'>'}</p>
            </a>
          </div>
          <p></p>
        </div>
        {/* <hr /> */}
        {/* <div className='video'>
          <div className='video-item'>
            <a
              href='https://drive.google.com/file/d/1AUgao0oI8PXcAm2XRll9ytrmAyYuhtnY/preview'
              data-fancybox='video'
              data-type='iframe'
              data-caption='Caption #1'
            >
              <img src='https://img.youtube.com/vi/tY3ShI1K7ac/maxresdefault.jpg' alt='' />
            </a>
          </div>
          <div className='video-item'>
            <a href='https://www.youtube.com/watch?v=zsxiE8tGvMs' data-fancybox='video' data-caption='Caption #2'>
              <img src='https://img.youtube.com/vi/zsxiE8tGvMs/maxresdefault.jpg' alt='' />
            </a>
          </div>
          <div className='video-item'>
            <a href='https://www.youtube.com/watch?v=h5mZYrUMz34' data-fancybox='video' data-caption='Caption #3'>
              <img src='https://img.youtube.com/vi/h5mZYrUMz34/maxresdefault.jpg' alt='' />
            </a>
          </div>
          <div className='video-item'>
            <a href='https://www.youtube.com/watch?v=BlDYh-k-OU0' data-fancybox='video' data-caption='Caption #4'>
              <img src='https://img.youtube.com/vi/BlDYh-k-OU0/maxresdefault.jpg' alt='' />
            </a>
          </div>
          <div className='video-item'>
            <a href='https://www.youtube.com/watch?v=ESAQfRPdfa0' data-fancybox='video' data-caption='Caption #5'>
              <img src='https://img.youtube.com/vi/ESAQfRPdfa0/maxresdefault.jpg' alt='' />
            </a>
          </div>
        </div> */}
        <div className='video'>
          {videos.map((video) => (
            <div className='video-item'>
              <a href={video.video_url} data-fancybox='video' data-type='html5video' data-caption={video.video_caption}>
                <img src={video.video_thumbnail_url} alt='' />
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default App;
