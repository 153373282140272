/** @format */

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Home from './Home';
import Photography from './Photography';
import Videography from './Videography';
import reportWebVitals from './reportWebVitals';
import { sendToVercelAnalytics } from './vitals';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Home />,
  },
  {
    path: '/photography',
    element: <Photography />,
  },
  {
    path: '/videography',
    element: <Videography />,
  },
]);

ReactDOM.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals(sendToVercelAnalytics);
